import { useState } from "react";
import NavDesktopLink from "./NavDesktopLink";
import NavDesktopMenu from "./NavDesktopMenu";
import NavLogo from "./NavLogo";
import NavMenuButton from "./NavMenuButton";
import NavMobileMenu from "./NavMobileMenu";
const mainMenu = ["home", "calendar", "menu", "orders"];
const subMenu = ["wallet", "profile", "help"];
export default function Nav({ label, newDishButton, hideButtons, }) {
    const [isOpen, setIsOpen] = useState(false);
    return (<>
      <div className="left-0 fixed px-8 top-0 bg-white w-full border-b-2 border-[#F5F5F5] py-5">
        <div className="md:relative max-w-7xl mx-auto flex items-center justify-between">
          <NavMobileMenu items={[...mainMenu, ...subMenu, "resources"]} onClose={() => setIsOpen(false)} isOpen={isOpen}/>
          <NavDesktopMenu items={subMenu} onClose={() => setIsOpen(false)} isOpen={isOpen}/>

          <NavLogo newDishButton={newDishButton} label={label}/>

          {!hideButtons ? (<div className="flex gap-3">
              {mainMenu.map((name) => (<NavDesktopLink key={name} name={name}/>))}
            </div>) : null}

          <div className="flex gap-5">
            {!hideButtons ? <NavDesktopLink name="resources" forceActive/> : null}
            <NavMenuButton onClick={() => setIsOpen((o) => !o)}/>
          </div>
        </div>
      </div>
    </>);
}
