import classNames from "classnames";
import ExclamationIcon from "../../assets/icons/exclamation.svg";
import ButtonStyle from "../../types/ButtonStyle";
import ElementSize from "../../types/ElementSize";
import Button from "../Global/Button";
export default function ActiveOrderRow({ onClick, cancelReason, }) {
    return (<div onClick={onClick} className={classNames("cursor-pointer table-row border-b-2 w-full last:border-0 border-t-2 border-[#f5f5f5]", {
            "bg-[#fdeded] border-[]": cancelReason,
        })}>
      <div className="align-middle table-cell py-4 px-2 truncate font-medium">1234567</div>
      <div className={classNames("align-middle w-1/2 truncate max-w-0 w-full table-cell py-4 px-2 font-medium", {
            "text-[#e94848]": cancelReason,
        })}>
        <div className="flex items-center gap-2">
          {cancelReason ? cancelReason : "Samantha"}
          {cancelReason ? <ExclamationIcon width="16" height="16"/> : null}
        </div>
      </div>
      <div className={classNames("align-middle hidden md:table-cell py-4 px-2 font-medium", {
            "line-through": cancelReason,
        })}>
        $4.64
      </div>
      <div className={classNames("align-middle hidden md:table-cell py-4 font-medium px-2", {
            "line-through": cancelReason,
        })}>
        $64.44
      </div>
      <div className="table-cell md:hidden py-4 font-medium px-2">
        <Button onClick={onClick} size={ElementSize.S} style={ButtonStyle.GREY}>
          View
        </Button>
      </div>
    </div>);
}
