import ElementSize from "../../types/ElementSize";
import ButtonStyle from "../../types/ButtonStyle";
import Button from "../Global/Button";
import ForChefsLogo from "../Global/ForChefsLogo";
export default function NavLogo({ label, newDishButton, }) {
    return (<>
      <div className="md:hidden items-center gap-3 flex font-bold text-xl leading-7">
        <span className="whitespace-nowrap">{label}</span>
        {newDishButton ? (<Button size={ElementSize.S} to="/dashboard/menu/add" style={ButtonStyle.BLACK}>
            + New dish
          </Button>) : null}
      </div>
      <div className="hidden md:block">
        <ForChefsLogo />
      </div>
    </>);
}
