import moment from "moment";
import { useState } from "react";
import Input from "../../components/Global/Input";
import MultiInput from "../../components/Global/MultiInput";
import Select from "../../components/Global/Select";
import TextArea from "../../components/Global/TextArea";
import INGREDIENTS from "../../mocks/ingredients";
import { DishAllergens, DishDietaryTypes, DishServing, DishType } from "../../types/Dish";
import FormWrapper from "./FormWrapper";
function DualInputContainer({ children }) {
    return <div className="flex w-full flex-col md:flex-row gap-5 items-start">{children}</div>;
}
export default function DishInfoForm({ currentDish }) {
    const [form, setForm] = useState({
        name: currentDish.name,
        price: `$${currentDish.price / 100}`,
        description: currentDish.description,
        ingredients: currentDish.ingredients,
        dietaryTypes: currentDish.dietaryTypes,
        portion: { serving: currentDish.portion.serving, size: currentDish.portion.size },
        type: currentDish.type,
        expiryDays: moment(currentDish.expiry).diff(moment(), "days"),
        reheatingInstructions: currentDish.reheatingInstructions,
        allergens: currentDish.allergens,
    });
    return (<FormWrapper label="Dish info">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex w-full flex-col gap-5 md:gap-8">
          <DualInputContainer>
            <Input placeholder="Tuscan-Grilled Chicken" required onInput={(e) => setForm((f) => ({ ...f, name: e.target.value }))} label="Dish title" value={form.name}/>
            <Input placeholder="$10.00" required onInput={(e) => {
            const val = e.target.value.replace(/[^0-9.]/g, "");
            setForm({
                ...form,
                price: `$${val}`,
            });
        }} label="Price" value={form.price}/>
          </DualInputContainer>
          <TextArea maxLength={2000} placeholder="Type here..." required onInput={(e) => setForm((f) => ({ ...f, description: e.target.value }))} label="Description" value={form.description}/>
          <MultiInput label="Ingredients" values={form.ingredients} options={INGREDIENTS} required setValues={(values) => setForm((f) => ({ ...f, ingredients: values }))}/>

          <Select required options={Object.keys(DishDietaryTypes)} setValues={(types) => setForm((f) => ({ ...f, dietaryTypes: types }))} label="Dietary" values={form.dietaryTypes}/>
          
        </div>
        <div className="flex w-full flex-col gap-5 md:gap-8">
          <DualInputContainer>
            <Input placeholder="1" label="Portion size" onInput={(e) => setForm((f) => ({
            ...f,
            portion: {
                ...f.portion,
                size: Number(e.target.value.replace(/[^0-9]/g, "")),
            },
        }))} value={form.portion.size}/>
            <Select single required options={Object.keys(DishServing)} setValues={(value) => setForm((f) => ({
            ...f,
            portion: {
                ...f.portion,
                serving: value[0],
            },
        }))} label="Type" values={[form.portion.serving]}/>
          </DualInputContainer>
          <DualInputContainer>
            <Select single required options={Object.keys(DishType)} setValues={(value) => setForm((f) => ({
            ...f,
            type: value[0],
        }))} label="Type" values={[form.type]}/>
            <Input placeholder="7" label="Expires in" onInput={(e) => setForm((f) => ({
            ...f,
            expiryDays: Number(e.target.value.replace(/[^0-9]/g, "")),
        }))} value={form.expiryDays} suffix="Days"/>
          </DualInputContainer>
          <TextArea maxLength={2000} placeholder="Type here..." required onInput={(e) => setForm((f) => ({
            ...f,
            reheatingInstructions: e.target.value,
        }))} height="242px" label="Reheating Instructions" value={form.reheatingInstructions}/>
          <Select required options={Object.keys(DishAllergens)} setValues={(types) => setForm((f) => ({ ...f, allergens: types }))} label="Allergens" values={form.allergens}/>
        </div>
      </div>
    </FormWrapper>);
}
