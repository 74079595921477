import { DishServing, DishType } from "../../types/Dish";
import ElementSize from "../../types/ElementSize";
import Input from "../Global/Input";
import InputLabel from "../Global/InputLabel";
import Select from "../Global/Select";
import TextArea from "../Global/TextArea";
export default function LastQuestions({ form, setForm, }) {
    return (<div className="flex md:pt-32 md:pb-40 flex-col gap-6">
      <div className="flex gap-5">
        <div className="w-32">
          <Input labelSize={ElementSize.L} placeholder="1" label="Portion size" onInput={(e) => setForm({
            ...form,
            portionSize: Number(e.target.value.replace(/[^0-9]/g, "")),
        })} value={form.portionSize}/>
        </div>
        <Select single labelSize={ElementSize.L} required options={Object.keys(DishServing)} setValues={(value) => setForm({
            ...form,
            portionServing: value[0],
        })} label="Serving" values={[form.portionServing]}/>
      </div>

      <div className="flex gap-5 items-start">
        <Select single labelSize={ElementSize.L} required options={Object.keys(DishType)} setValues={(value) => setForm({
            ...form,
            type: value[0],
        })} label="Dish type" values={[form.type]}/>
        <div className="max-w-[110px] min-w-[110px]">
          <Input labelSize={ElementSize.L} placeholder="7" label="Expires in" onInput={(e) => setForm({
            ...form,
            expiryDays: Number(e.target.value.replace(/[^0-9]/g, "")),
        })} value={form.expiryDays} suffix="Days"/>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <InputLabel labelSize={ElementSize.L} text="Reheating instructions"/>
        <span className="text-[#909192] mb-2 text-xs font-medium">
          Enter the reheating instructions that will be shown on your label. Example: Please
          microwave for 2 minutes on high.
        </span>
        <TextArea maxLength={2000} onInput={() => { }} value="" placeholder="Type here..." required/>
      </div>
      <div className="flex flex-col">
        <span className="font-bold mb-1 block md:text-2xl">Item order limit</span>
        <span className="font-medium text-xs mb-3 block text-[#979797]">
          How many of this dish do you want to offer on a given day
        </span>
        <div className="w-28">
          <Input onInput={() => { }} placeholder="7 - 100" value="" required/>
        </div>
      </div>
    </div>);
}
