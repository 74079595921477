import classNames from "classnames";
import ChevLeftIcon from "../../assets/icons/chev-left.svg";
import ChevRightIcon from "../../assets/icons/chev-right.svg";
export default function DishesPagination({ page, onIncrement, onDecrement, canIncrement, canDecrement, }) {
    return (<div className="fixed md:static bottom-0 border-t-2 md:border-t-0 border-[#f5f5f5] bottom-0 left-0 bg-white w-full">
      <div className="flex justify-end p-5 md:p-0 md:pt-8 items-center">
        <button type="button" disabled={!canDecrement} onClick={onDecrement} className={classNames("flex transition-all duration-200 justify-center items-center rounded-full h-9 w-9 bg-[#f5f5f5]", { "opacity-40": !canDecrement })}>
          <ChevLeftIcon width="8" height="14"/>
        </button>
        <span className="w-9 flex justify-center items-center font-medium text-xl leading-7">
          {page + 1}
        </span>
        <button type="button" disabled={!canIncrement} onClick={onIncrement} className={classNames("flex transition-all duration-200 justify-center items-center rounded-full h-9 w-9 bg-[#f5f5f5]", { "opacity-40": !canIncrement })}>
          <ChevRightIcon width="8" height="14"/>
        </button>
      </div>
    </div>);
}
