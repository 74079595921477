import Nav from "../components/Nav";
import DishesImage from "../assets/images/dishes.png";
import Button from "../components/Global/Button";
import ButtonStyle from "../types/ButtonStyle";
export default function OutOfAreaView() {
    return (<>
      <div className="text-center px-12 flex flex-col mt-44 justify-center w-full items-center">
        <img src={DishesImage} className="w-36 md:w-48"/>
        <div className="font-bold mt-8 text-2xl md:text-[32px] md:leading-10 leading-8">
          Stay tuned. We’re coming to your city soon.
        </div>
        <div className="text-[#999999] mt-2 mb-10 font-medium">
          Thank you for your application! We’ll reach out once we’re in your city.
        </div>
        <div className="w-56">
        <Button onClick={() => { }} style={ButtonStyle.BLACK}>
          Follow along
        </Button>
        </div>
      </div>
      <Nav hideButtons label="Good afternoon, Emilye"/>
    </>);
}
