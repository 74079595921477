import { useState } from "react";
import AddPhoto from "../components/AddDishView/AddPhoto";
import ChooseTitle from "../components/AddDishView/ChooseTitle";
import DescribeDish from "../components/AddDishView/DescribeDish";
import DietaryAllergens from "../components/AddDishView/DietaryAllergens";
import OrderDetails from "../components/OrdersView/OrderDetails";
import LastQuestions from "../components/AddDishView/LastQuestions";
import ListIngredients from "../components/AddDishView/ListIngredients";
import ProgressBar from "../components/AddDishView/ProgressBar";
import SetYourPrice from "../components/AddDishView/SetYourPrice";
import TopSideBar from "../components/AddDishView/TopSideBar";
import Button from "../components/Global/Button";
import ButtonStyle from "../types/ButtonStyle";
import ElementSize from "../types/ElementSize";
export default function AddDishView() {
    const [showExit, setShowExit] = useState(false);
    const [step, setStep] = useState(0);
    const [form, setForm] = useState({
        title: "",
        description: "",
        price: "",
        ingredients: [],
        dietaryTypes: [],
        allergens: [],
        expiryDays: "",
        orderLimit: "",
        portionServing: "",
        portionSize: "",
        reheatingInstructions: "",
        type: "",
    });
    const onSubmit = (e) => {
        e.preventDefault();
        setStep((s) => s + 1);
    };
    return (<div className="md:min-h-screen flex flex-col md:flex-row">
      <TopSideBar onClose={() => setShowExit(true)} step={step}/>

      {showExit ? <OrderDetails onClose={() => setShowExit(false)}/> : null}

      <div className="absolute top-8 right-12">
        <Button style={ButtonStyle.GREY} size={ElementSize.S} onClick={() => setShowExit(true)}>
          Save and Exit
        </Button>
      </div>

      <form className="w-full md:flex justify-center items-center" onSubmit={onSubmit}>
        <div className="px-8 md:px-20 mt-7 md:m-0 w-full">
          {step === 0 ? (<ChooseTitle form={form} setForm={setForm}/>) : step === 1 ? (<AddPhoto />) : step === 2 ? (<DescribeDish form={form} setForm={setForm}/>) : step === 3 ? (<SetYourPrice form={form} setForm={setForm}/>) : step === 4 ? (<ListIngredients form={form} setForm={setForm}/>) : step === 5 ? (<DietaryAllergens form={form} setForm={setForm}/>) : step === 6 ? (<LastQuestions form={form} setForm={setForm}/>) : null}
          <div className="pt-[180px] md:pt-0"></div>
        </div>

        <ProgressBar percentage={((step + 1) / 7) * 100} onBack={() => setStep((s) => (s <= 0 ? 0 : s - 1))}/>
      </form>
    </div>);
}
