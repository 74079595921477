import classNames from "classnames";
import { useRef, useState } from "react";
import ChevIcon from "../../../assets/icons/chev-down.svg";
import ElementSize from "../../../types/ElementSize";
import { randomString } from "../../../utils";
import InputLabel from "../InputLabel";
import ButtonText from "./ButtonText";
import Dropdown from "./Dropdown";
export default function Select({ label, required, values, options, single, size, setValues, labelSize, }) {
    if (!size)
        size = ElementSize.M;
    const [open, setOpen] = useState(false);
    const [dirty, setDirty] = useState(false);
    const id = useRef(label ? label.toLowerCase().replace(/[^a-z]/g, "_") + "_" + randomString() : randomString());
    const requiredError = dirty && required && values.length === 0;
    return (<div className="flex w-full relative flex-col">
      {label ? (<InputLabel labelSize={labelSize} showRequiredError={requiredError} htmlFor={id.current} text={label}/>) : null}
      <div className="relative">
        <button type="button" onClick={() => setOpen((o) => !o)} className={classNames("truncate w-full pr-12 figma-shadow text-left rounded-xl resize-none min-w-0 border transition-all duration-200 border-[#D1D5DB] font-medium text-base md:text-lg", {
            "bg-[#f1e1e1] border-[#E94848]": requiredError,
            "py-2.5 px-4 rounded-100": size === ElementSize.S,
            "py-4 px-6": size === ElementSize.M,
            "mt-2": label,
        })}>
          <ButtonText values={values}/>
        </button>
        <ChevIcon width="12" height="6" className={classNames("transform transition-all duration-200 absolute right-6 pointer-events-none", { "rotate-180": open, "top-10": label, "top-5": !label && size === ElementSize.S })}/>
        <Dropdown open={open} onClick={(o) => {
            setDirty(true);
            if (single) {
                setValues([o]);
                setOpen(false);
            }
            else {
                setValues(values.includes(o) ? values.filter((v) => v !== o) : [...values, o]);
            }
        }} options={options} values={values}/>
      </div>
    </div>);
}
