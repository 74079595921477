import { useState } from "react";
import ActiveOrderRow from "./ActiveOrderRow";
import OrderDetails from "./OrderDetails";
export default function ActiveOrders() {
    const [showDetails, setShowDetails] = useState(false);
    return (<>
      <div className="table w-full border-collapse mt-5 md:mt-8">
        <div className="hidden md:table-row">
          <div className="table-cell pb-4 px-2 text-xs font-medium text-[#999999]">
            Order ID (#)
          </div>
          <div className="table-cell pb-4 px-2 text-xs font-medium text-[#999999]">Customer</div>
          <div className="hidden md:table-cell pb-4 px-2 text-xs font-medium text-[#999999]">
            NB Fee
          </div>
          <div className="hidden md:table-cell pb-4 px-2 text-xs font-medium text-[#999999]">
            Total
          </div>
        </div>

        {[...Array(20)].map((_, i) => (<ActiveOrderRow cancelReason={i === 3 ? "Customer cancelled" : ""} onClick={() => setShowDetails(true)}/>))}
      </div>

      {showDetails ? <OrderDetails onClose={() => setShowDetails(false)}/> : null}
    </>);
}
