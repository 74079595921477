import ElementSize from "../../types/ElementSize";
import TextArea from "../Global/TextArea";
export default function DescribeDish({ form, setForm, }) {
    return (<div>
      <span className="font-medium text-sm md:text-lg">Dish description example</span>
      <p className="font-medium text-sm text-[#909192] md:text-lg mb-8 block">
        Tender, juicy flat iron steak, grilled to perfection. Bed of creamy mashed potatoes as a
        hearty accompaniment. Side of French green beans and sautéed carrots tossed with garlic oil,
        lemon, and herbs. Topped with a my mothers secret creamy peppercorn sauce recipe, made with
        yogurt and porcini mushrooms.
      </p>
      <TextArea labelSize={ElementSize.L} maxLength={2000} placeholder="Type here..." height="100px" label="Create Description" required onInput={(e) => setForm({ ...form, description: e.target.value })} value={form.description}/>
    </div>);
}
