export default function PhotoExamples() {
    return (<div className="flex flex-col mt-8 md:mt-0 md:flex-row gap-6 md:gap-12">
      <div className="flex flex-col gap-4">
        <span className="font-bold md:font-normal md:text-sm">Accepted Photos</span>
        <div className="flex gap-6">
          {[...Array(4)].map((_, i) => (<img key={i} className="w-14 h-14" src={`https://picsum.photos/100?id=${i}1`}/>))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <span className="font-bold md:font-normal md:text-sm">Rejected Photos</span>
        <div className="flex gap-6">
          {[...Array(4)].map((_, i) => (<img key={i} className="w-14 h-14" src={`https://picsum.photos/100?id=${i}2`}/>))}
        </div>
      </div>
    </div>);
}
