import classNames from "classnames";
import { Route, Routes } from "react-router-dom";
import Nav from "../components/Nav";
import ActiveOrders from "../components/OrdersView/ActiveOrders";
import OrdersNav from "../components/OrdersView/OrdersNav";
import RefundedOrders from "../components/OrdersView/RefundedOrders";
import SoldOrders from "../components/OrdersView/SoldOrders";
function SingleBlock({ white, label, value }) {
    return (<div className={classNames("flex flex-col py-5 px-6 rounded-2xl", {
            "bg-white border-2 border-[#F5F5F5]": white,
            "bg-black": !white,
        })}>
      <span className={"text-xs font-medium text-[#999999]"}>{label}</span>
      <span className={`text-2xl font-bold ${white ? "text-black" : "text-white"}`}>{value}</span>
    </div>);
}
const items = [
    { key: "Date", value: "5/16/22" },
    { key: "Dishes", value: "23" },
    { key: "Dishes", value: "23" },
    { key: "Customers", value: "20" },
    { key: "Tips", value: "$213" },
    { key: "Payout (75% + tips)", value: "$816" },
];
export default function OrdersView() {
    return (<>
      <div className="mt-[82px] p-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid mb-6 md:grid-cols-6 grid-cols-2 gap-2">
            {items.map((item, i) => (<SingleBlock label={item.key} value={item.value} white={i === 0}/>))}
          </div>

          <OrdersNav />

          <Routes>
            <Route path="sold" element={<SoldOrders />}/>
            <Route path="active" element={<ActiveOrders />}/>
            <Route path="refunded" element={<RefundedOrders />}/>
          </Routes>

          <span className="mt-8 block mx-auto text-center mb-8 px-8 font-bold text-[#999999]">
            Ordering for 5/16 ends Saturday, May 14 at 11:59 PM
          </span>
        </div>
      </div>
      <Nav label="Orders"/>
    </>);
}
