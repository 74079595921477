import ElementSize from "../../types/ElementSize";
import ButtonStyle from "../../types/ButtonStyle";
import Button from "../Global/Button";
import TextArea from "../Global/TextArea";
import FormWrapper from "./FormWrapper";
const comments = [
    {
        id: 1,
        imageUrl: "https://picsum.photos/100?1",
        text: "Hi Emily! Make sure to shoot your photo from above, not sideways.",
    },
    {
        id: 2,
        imageUrl: "https://picsum.photos/100?2",
        text: "This is a comment that has a lot more text. This way I can test if the variable line length is displayed correctly! AAAAAAAAAAAAAAAAAAAA",
    },
    {
        id: 3,
        imageUrl: "https://picsum.photos/100?3",
        text: "Thanks for letting me know. It is fixed now! ",
    },
];
export default function DishComments() {
    return (<FormWrapper label="Comments" subText="Our team will leave comments here if there is ever any issues with your dishes or account.">
      <div className="max-w-3xl flex mt-4 flex-col gap-2">
        {comments.map((comment, i) => (<div className="flex gap-4" key={comment.id}>
            <div className="flex items-center gap-2 flex-col">
              <img className="w-10 h-10 rounded-full min-w-[40px]" src={comment.imageUrl}/>
              <div className={`w-px h-full ${i === 0 ? "bg-black" : "bg-[#E9E9E9]"}`}></div>
            </div>
            <div className="mb-3 border text-sm font-medium py-3 px-4 rounded-2xl border-black">
              {comment.text}
            </div>
          </div>))}
        <div>
          <div className="flex gap-4">
            <img className="w-10 h-10 rounded-full min-w-[40px]" src="https://picsum.photos/100?123"/>
            <div className="relative w-full">
              <TextArea onInput={() => { }} maxLength={2000} placeholder="Leave a comment" height="120px" value="" required/>
              <div className="w-20 absolute bottom-10 right-4">
                <Button style={ButtonStyle.BLACK} size={ElementSize.S}>
                  Post
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>);
}
