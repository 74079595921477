import Button from "../Global/Button";
import ButtonStyle from "../../types/ButtonStyle";
import Input from "../Global/Input";
import FormWrapper from "./FormWrapper";
export default function DishLimitForm() {
    return (<FormWrapper label="Daily limit" subText="How many of this dish do you want to offer on a given day">
      <div className="w-28 mt-4">
        <Input value="" onInput={() => { }} placeholder="5 - 100" required/>
      </div>

      <div className="flex mt-6 md:mt-10 gap-4 items-start justify-end">
        <div className="w-full md:w-36">
          <Button onClick={() => { }} style={ButtonStyle.GREY}>
            Delete dish
          </Button>
        </div>
        <div className="w-full md:w-36">
          <Button style={ButtonStyle.BLACK}>Save</Button>
        </div>
      </div>
    </FormWrapper>);
}
