String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};
String.prototype.titleCase = function () {
    return this.replaceAll("_", " ")
        .split(" ")
        .map((s) => s.capitalize())
        .join(" ");
};
export {};
