import LinksCard from "../components/HomeView/LinksCard";
import ResourceCenter from "../components/HomeView/ResourceCenter";
import Nav from "../components/Nav";
export default function HomeView() {
    return (<>
      <Nav label="Good afternoon, Emilye"/>
      <div className="mt-[81px] pb-32 md:min-h-[calc(100vh_-_81px)] px-8 py-8 bg-[#f5f5f5]">
        <div className="max-w-7xl flex md:flex-row flex-col gap-8 mx-auto">
          <LinksCard />
          <ResourceCenter />
        </div>
      </div>
    </>);
}
