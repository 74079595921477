import NavMenuCloseButton from "../../Nav/NavMenuCloseButton";
import CommentCount from "../CommentCount";
export default function MobileTitle({ label, comments }) {
    return (<>
      <div className="z-10 border-b-2 border-[#f5f5f5] flex flex-col md:hidden fixed top-0 left-0 px-8 bg-white w-full">
        <NavMenuCloseButton onClick={() => window.history.back()}/>
        <div className="pb-6 pt-4 flex justify-between">
          <span className="font-bold pr-4 truncate text-xl">{label}</span>
          {comments ? <CommentCount amount={2}/> : null}
        </div>
      </div>
      <div className="md:hidden pt-18"></div>
    </>);
}
