import { useEffect, useRef } from "react";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import HomeView from "./pages/HomeView";
import MenuView from "./pages/MenuView";
import OnboardingView from "./pages/OnboardingView";
import OrdersView from "./pages/OrdersView";
import OutOfAreaView from "./pages/OutOfAreaView";
export default function App() {
    const prevLoc = useRef("");
    useEffect(() => {
        const newLoc = location.pathname;
        if (newLoc !== prevLoc.current) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "auto",
            });
            prevLoc.current = newLoc;
        }
    });
    return (<Routes>
      <Route path="/home" element={<HomeView />}/>
      <Route path="/orders/*" element={<OrdersView />}/>
      <Route path="/onboarding" element={<OnboardingView />}/>
      <Route path="/out-of-area" element={<OutOfAreaView />}/>
      <Route path="/dashboard/menu/*" element={<MenuView />}/>
      <Route path="*" element={<Navigate to="/dashboard/menu" replace/>}/>
    </Routes>);
}
