import classNames from "classnames";
import { useRef, useState } from "react";
import { randomString } from "../../../utils";
import InputLabel from "../InputLabel";
export default function TextArea({ label, onInput, required, placeholder, value, maxLength, height, labelSize, }) {
    const [dirty, setDirty] = useState(false);
    const id = useRef(label ? label.toLowerCase().replace(/[^a-z]/g, "_") + "_" + randomString() : randomString());
    const requiredError = dirty && required && !value;
    return (<div className="flex w-full gap-2 flex-col">
      {label ? (<InputLabel labelSize={labelSize} showRequiredError={requiredError} htmlFor={id.current} text={label}/>) : null}
      <textarea id={id.current} required={required} onInput={(e) => {
            setDirty(true);
            onInput(e);
        }} placeholder={requiredError ? "" : placeholder} value={value} style={{ height }} maxLength={maxLength} className={classNames("resize-none h-44 figma-shadow min-w-0 w-full rounded-xl border transition-all duration-200 border-[#D1D5DB] font-medium text-base md:text-lg py-4 px-6", {
            "bg-[#f1e1e1] border-[#E94848]": requiredError,
        })}/>
      <span className="text-sm text-[#909192]">
        {value.length} / {maxLength} characters
      </span>
    </div>);
}
