import { useEffect, useState } from "react";
import AddImageIcon from "../../assets/icons/add-image.svg";
var ErrorState;
(function (ErrorState) {
    ErrorState["BAD_FILE"] = "BAD_FILE";
    ErrorState["GOOD_FILE"] = "GOOD_FILE";
    ErrorState["DEFAULT"] = "DEFAULT";
})(ErrorState || (ErrorState = {}));
function AddImage({ acceptedFiles, maxFileSize, file, }) {
    const [thumb, setThumb] = useState(null);
    useEffect(() => {
        (async () => {
            if (!file)
                return setThumb(null);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => setThumb(reader.result);
        })();
    }, [file]);
    return (<div className="p-6 center flex flex-col gap-1 items-center">
      <div className="flex items-center">
        {thumb ? <img className="h-9 w-9" src={thumb}/> : null}
        <AddImageIcon width="48" height="48" className="text-[#909192]"/>
      </div>
      <div className="gap-1 font-medium hidden md:flex">
        <span className="text-sm">Upload a photo</span>
        <span className="text-sm text-[#909192]">or drag and drop</span>
      </div>
      <span className="font-medium text-sm md:hidden">Change dish photo</span>
      <span className="text-xs font-medium text-[#909192] opacity-60">
        {acceptedFiles.join(", ").replace(/\./g, "").toUpperCase()} up to {maxFileSize / 100000}MB
      </span>
    </div>);
}
function DragArea({ maxFileSize, acceptedFiles, file, setFile, }) {
    const [dragging, setDragging] = useState(false);
    const [error, setError] = useState(ErrorState.DEFAULT);
    useEffect(() => {
        setTimeout(() => setError(ErrorState.DEFAULT), 1000);
    }, [error]);
    const addFile = (file) => {
        if (!file)
            return;
        setError(ErrorState.DEFAULT);
        setDragging(false);
        let good = false;
        for (const ext of acceptedFiles) {
            if (file.name.toLowerCase().endsWith(ext)) {
                good = true;
                break;
            }
        }
        if (!good || file.size > maxFileSize) {
            setFile(null);
            setError(ErrorState.BAD_FILE);
            return;
        }
        setFile(file);
        setError(ErrorState.GOOD_FILE);
    };
    return (<label onDragOver={(e) => {
            e.preventDefault();
            setDragging(true);
        }} onDragLeave={(e) => {
            e.preventDefault();
            setDragging(false);
        }} onDrop={(e) => {
            e.preventDefault();
            addFile(e.dataTransfer.files[0]);
        }} className={`flex mt-4 bg-white justify-center items-center relative transition-all duration-300 border-2 border-dashed ${error === ErrorState.DEFAULT
            ? "border-[#d1d5db]"
            : error === ErrorState.GOOD_FILE
                ? "border-green-500"
                : "border-red-500"} cursor-pointer rounded-md`}>
      <AddImage file={file} acceptedFiles={acceptedFiles} maxFileSize={maxFileSize}/>
      <input className="w-0 h-0 top-0 left-0 opacity-0 pointer-events-none absolute" onInput={(e) => {
            const input = e.target;
            addFile(input.files ? input.files[0] : null);
        }} type="file" accept={acceptedFiles.join(",")} required/>
    </label>);
}
export default function PhotoUpload({ maxFileSize, acceptedFiles, file, setFile, }) {
    return (<DragArea maxFileSize={maxFileSize} acceptedFiles={acceptedFiles} file={file} setFile={setFile}/>);
}
