export default function SoldOrders() {
    return (<>
      <div className="table border-collapse mt-5 w-full md:mt-8">
        <div className="hidden md:table-row">
          <div className="table-cell pb-4 text-xs font-medium text-[#999999]">Dish name</div>
          <div className="table-cell pb-4 text-xs font-medium text-[#999999]">
            Quantity
          </div>
        </div>

        {[...Array(20)].map((_, i) => (<div className="table-row border-b-2 last:border-0 border-t-2 border-[#f5f5f5] ">
            <div className="max-w-0 py-4 pr-4 w-full table-cell truncate font-medium">
              Vietnamese Grilled Lemongrass Bla Bla Long Name
            </div>
            <div className="table-cell py-4">
              <div className="border font-medium border-[#00BA34] bg-[#e6f9eb] text-[#00BA34] rounded-md h-8 w-12 flex justify-center items-center">
                43
              </div>
            </div>
          </div>))}

      </div>
    </>);
}
