import ElementSize from "../../types/ElementSize";
import Input from "../Global/Input";
export default function SetYourPrice({ form, setForm, }) {
    return (<div>
      <div className="max-w-[200px]">
        <Input labelSize={ElementSize.L} onInput={(e) => {
            const val = e.target.value.replace(/[^0-9.]/g, "");
            setForm({
                ...form,
                price: `$${val}`,
            });
        }} value={form.price} size={ElementSize.L} placeholder="$9.99" required label="Price"/>
      </div>
      <div className="mt-8 h-32 w-full rounded-2xl bg-[#F5F5F5] relative">
        <div className="px-6 absolute font-bold top-0 md:text-lg flex justify-center items-center h-full rounded-l-2xl bg-[#e5f7de] left-0 w-11/12">
          <div>
            You will earn{" "}
            <span className="font-black">
              ${(Number(form.price.replace("$", "")) * 0.75).toFixed(2)}
            </span>{" "}
            from each dish sold!
          </div>
        </div>
      </div>
      <span className="font-medium md:text-sm text-xs text-[#909192] block mt-4 leading-5">
        Neighbors collects ${(Number(form.price.replace("$", "")) * 0.25).toFixed(2)} (25%) to fund
        operations. Chefs who use Neighbors as their only payment method have seen sales increase by
        200%+ on average.
      </span>
    </div>);
}
