import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DishComments from "../components/DishDetailView/DishComments";
import DishInfoForm from "../components/DishDetailView/DishInfoForm";
import DishLimitForm from "../components/DishDetailView/DishLimitForm";
import DishPhotoForm from "../components/DishDetailView/DishPhotoForm";
import ContentContainer from "../components/Global/ContentContainer";
import DesktopTitle from "../components/Global/DesktopTitle";
import MobileTitle from "../components/Global/MobileTitle";
import Nav from "../components/Nav";
import { GET_DISH } from "../mocks/dishes";
export default function DishDetailView() {
    const { id } = useParams();
    const [currentDish, setCurrentDish] = useState();
    useEffect(() => {
        if (!id)
            return;
        GET_DISH(id).then((r) => {
            setCurrentDish(JSON.parse(JSON.stringify(r)));
        });
    }, []);
    if (!currentDish)
        return null;
    const title = `Editting ${currentDish.name}`;
    return (<ContentContainer>
      <DesktopTitle label={title}/>
      <MobileTitle label={title} comments={currentDish.comments.length}/>
      <DishInfoForm currentDish={currentDish}/>
      <div className="hidden md:block my-2 h-0.5 bg-[#F5F5F5] w-full"></div>
      <DishPhotoForm />
      <div className="hidden md:block my-2 h-0.5 bg-[#F5F5F5] w-full"></div>
      <DishLimitForm />
      <div className="hidden md:block my-2 h-0.5 bg-[#F5F5F5] w-full"></div>
      <DishComments />
      <Nav label={title} newDishButton/>
      <div className="h-32 w-full"></div>
    </ContentContainer>);
}
