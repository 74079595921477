import classNames from "classnames";
import { useRef, useState } from "react";
import { randomString } from "../../../utils";
import InputLabel from "../InputLabel";
import Dropdown from "./Dropdown";
import SelectedValue from "./SelectedValue";
export default function MultiInput({ label, required, values, setValues, options, labelSize, }) {
    const [input, setInput] = useState("");
    const [dirty, setDirty] = useState(false);
    const id = useRef(label.toLowerCase().replace(/[^a-z]/g, "_") + "_" + randomString());
    const requiredError = dirty && required && values.length === 0;
    const filteredOptions = options
        ? options.filter((val) => val.toLowerCase().includes(input.toLowerCase()))
        : [];
    return (<div className="flex gap-2 flex-col">
      <InputLabel labelSize={labelSize} showRequiredError={requiredError} htmlFor={id.current} text={label}/>
      <div id={id.current} className={classNames("flex gap-2 relative figma-shadow flex-wrap min-w-0 w-full rounded-xl border transition-all duration-200 border-[#D1D5DB] font-medium text-base md:text-lg p-5", {
            "bg-[#f1e1e1] border-[#E94848]": requiredError,
        })}>
        {values.map((val, index) => (<SelectedValue onClick={() => {
                setDirty(true);
                setValues(values.filter((_, i) => index !== i));
            }} text={val} key={`${val}${index}`}/>))}
        <input value={input} className={classNames("w-full font-medium min-w-0 bg-transparent transition-all duration-200", { "mt-2": values.length > 0 })} onInput={(e) => setInput(e.target.value)} onKeyDown={(e) => {
            setDirty(true);
            if (e.key === "Enter") {
                e.preventDefault();
                setValues([...values, e.target.value]);
                setInput("");
            }
        }} placeholder="Enter more..."></input>
        {input.length > 0 && filteredOptions.length > 0 ? (<Dropdown values={filteredOptions} onClick={(val) => {
                setInput("");
                setValues([...values, val]);
            }}/>) : null}
      </div>
    </div>);
}
