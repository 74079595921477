import { useState } from "react";
import PhotoUpload from "../DishDetailView/PhotoUpload";
export default function AddPhoto() {
    const [file, setFile] = useState(null);
    return (<div className="pb-24">
      <PhotoUpload acceptedFiles={[".jpeg", ".jpg", ".png"]} file={file} setFile={setFile} maxFileSize={1000000}/>

      <span className="font-bold mt-8 block mb-1">Photo guidelines</span>
      <p className="text-[#979797]">
        Take a photo from <span className="font-medium">directly above</span> of your dish on a
        circular <span className="font-medium">all-white</span> bowl or plate, ensuring no edges of
        the dish are cut off. Use natural sunlight to make your dish colors pop! Photos that don’t
        follow guidelines will not be approved.
      </p>
      
      <div className="flex flex-col mt-8 gap-6 md:gap-12">
        <div className="flex flex-col gap-4">
          <span className="font-bold">Accepted Photos</span>
          <div className="flex gap-6">
            {[...Array(4)].map((_, i) => (<img key={i} className="w-14 md:w-24 h-14 md:h-24" src={`https://picsum.photos/100?id=${i}1`}/>))}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <span className="font-bold">Rejected Photos</span>
          <div className="flex gap-6">
            {[...Array(4)].map((_, i) => (<img key={i} className="w-14 md:w-24 h-14 md:h-24" src={`https://picsum.photos/100?id=${i}2`}/>))}
          </div>
        </div>
      </div>
    </div>);
}
