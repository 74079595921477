import Button from "../Global/Button";
import ButtonStyle from "../../types/ButtonStyle";
import ElementSize from "../../types/ElementSize";
import BrowserContentImage from "../../assets/images/browser-content.png";
export default function ResourceCenter() {
    return (<div className="bg-white md:w-2/3 w-full rounded-2xl flex flex-col items-center">
      <span className="pt-6 pb-2 md:pt-12 block font-bold text-2xl md:text-5xl">
        Resource Center
      </span>
      <span className="font-medium block pb-5 md:text-2xl text-[#999999]">
        Questions? There’s a guide for that!
      </span>
      <div className="pb-12">
        <Button onClick={() => { }} style={ButtonStyle.GREY} size={ElementSize.S}>
          Guide me
        </Button>
      </div>

      <img className="w-full max-w-md mt-auto rounded-b-2xl md:rounded-none" src={BrowserContentImage}/>
    </div>);
}
