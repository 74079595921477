import { NavLink } from "react-router-dom";
import { DishState } from "../../types/Dish";
export default function DishesNav({ dishes, dishCount }) {
    const activeClasses = "-mb-[2px] transition-all duration-200 font-medium border-b-2 border-black block pb-4 pt-5";
    const inactiveClasses = "-mb-[2px] transition-all duration-200 border-b-2 border-[#F5F5F5] font-medium block text-[#999999] pb-4 pt-5";
    return (<div className="fixed px-8 md:px-0 top-[82px] bg-white pb-4 md:pb-0 flex gap-8 md:static border-b-2 md:border-b-0 border-[#F5F5F5] left-0 w-full">
      <div className="flex gap-8 w-full border-b-2 border-[#F5F5F5]">
        <NavLink end className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)} to="/dashboard/menu">
          All Dishes ({dishCount})
        </NavLink>
        <NavLink end className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)} to="/dashboard/menu/live">
          Live Dishes ({dishes.filter((d) => d.state === DishState.LIVE).length})
        </NavLink>
      </div>
    </div>);
}
