import { useState } from "react";
import ButtonStyle from "../../types/ButtonStyle";
import ElementSize from "../../types/ElementSize";
import Button from "../Global/Button";
import OrderDetails from "./OrderDetails";
function NormalRow({ onClick, }) {
    return (<div onClick={onClick} className={"cursor-pointer table-row border-b-2 w-full last:border-0 border-t-2 border-[#f5f5f5]"}>
      <div className="align-middle table-cell py-4 px-2 truncate font-medium">1234567</div>
      <div className={"align-middle hidden md:table-cell py-4 px-2 font-medium"}>$132.44</div>
      <div className={"align-middle hidden md:table-cell py-4 px-2 font-medium"}>$4.64</div>
      <div className={"align-middle table-cell truncate max-w-0 w-full md:max-w-auto md:w-auto py-4 font-medium px-2"}>Customer cancellation</div>
      <div className="table-cell md:hidden py-4 font-medium px-2">
        <Button onClick={onClick} size={ElementSize.S} style={ButtonStyle.GREY}>
          View
        </Button>
      </div>
    </div>);
}
export default function RefundedOrders() {
    const [showDetails, setShowDetails] = useState(false);
    return (<>
      <div className="table w-full border-collapse mt-5 md:mt-8">
        <div className="hidden md:table-row">
          <div className="table-cell pb-4 px-2 text-xs font-medium text-[#999999]">
            Order ID (#)
          </div>
          <div className="table-cell pb-4 px-2 text-xs font-medium text-[#999999]">Earnings</div>
          <div className="hidden md:table-cell pb-4 px-2 text-xs font-medium text-[#999999]">
            Earnings After Refund
          </div>
          <div className="hidden md:table-cell pb-4 px-2 text-xs font-medium text-[#999999]">
            Refund Reason
          </div>
        </div>

        {[...Array(20)].map((_, i) => (<NormalRow onClick={() => setShowDetails(true)}/>))}
      </div>

      {showDetails ? <OrderDetails onClose={() => setShowDetails(false)}/> : null}
    </>);
}
