import moment from "moment";
import { DishAllergens, DishDietaryTypes, DishServing, DishState, DishType, } from "../types/Dish";
import { getRandomEnumValue } from "../utils";
const DISHES = [...Array(50)].map((_, index) => {
    return {
        id: index,
        allergens: [DishAllergens.PEANUTS],
        comments: [...Array(Math.ceil(Math.random() * 10))].map(() => ({
            imageUrl: `https://picsum.photos/32?id=${index}`,
            text: "I’ve never, ever, ever, ever, ever met someone I believe in as little as you.",
        })),
        description: "This lamb is so undercooked, it's following Mary to school!",
        expiry: moment().add(7, "days").toDate(),
        imageUrl: `https://picsum.photos/32?id=${index}`,
        ingredients: ["peanuts"],
        name: `Super Duper Nice Peanut Cake Deluxe #${index}`,
        portion: { serving: DishServing.OZ_CONTAINER, size: 2 },
        price: Math.ceil(Math.random() * 1000),
        state: getRandomEnumValue(DishState),
        type: DishType.ENTREE,
        dietaryTypes: [DishDietaryTypes.VEGAN, DishDietaryTypes.GLUTEN_FREE],
        reheatingInstructions: "You don't microwave a salad",
        dailyLimit: Math.ceil(Math.random() * 10),
    };
});
export const GET_DISHES = async () => DISHES;
export const GET_DISH = async (index) => DISHES[typeof index === "number" ? index : Number(index)];
