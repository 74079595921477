import ChevRightIcon from "../../assets/icons/chev-right.svg";
import classNames from "classnames";
const items = [
    "Add at least 5 menu items",
    "Set bio and profile photo",
    "Add food handlers certificate",
    "Set your delivery hub",
    "Sign community guidelines",
    "Add bank account",
    "Set your availability",
];
export default function LinksCard() {
    return (<div className="bg-white w-full md:w-1/3 pb-0 p-6 rounded-2xl">
      <span className="font-bold block mb-4">Next steps for you</span>

      <ul className="font-medium">
        {items.map((item, i) => (<a href={`#${item.toLowerCase().replace(/\s/g, "-")}`} key={item} className={classNames("border-b-2 border-[#f5f5f5] last:border-b-0 last:pb-6 py-4 flex items-center gap-2", {
                "line-through text-[#979797]": i === 2,
            })}>
            <span>{item} </span>
            <ChevRightIcon width="10" height="10" className="mt-0.5"/>
          </a>))}
      </ul>
    </div>);
}
