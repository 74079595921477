import { Fragment, useEffect, useState } from "react";
import ContentContainer from "../Global/ContentContainer";
import DesktopTitle from "../Global/DesktopTitle";
import Nav from "../Nav";
import DishesNav from "./DishesNav";
import DishesPagination from "./DishesPagination";
import SingleDishRowDesktop from "./SingleDishRowDesktop";
import SingleDishRowMobile from "./SingleDishRowMobile";
const MAX_ITEMS_PER_PAGE = window.innerWidth < 768 ? 15 : 6;
function TableRow({ widths }) {
    const getBaseTdClass = (i) => {
        const padding = i === 0 ? "pr-4" : i === widths.length - 1 ? "pl-4" : "px-4";
        return `${widths[i]} whitespace-nowrap pb-4 ${padding} text-[#999999] text-xs font-medium table-cell`;
    };
    return (<div className="hidden border-b-2 last:border-b-0 border-[#f5f5f5] md:table-row">
      <div className={getBaseTdClass(0)}>Dish Name</div>
      <div className={getBaseTdClass(1)}></div>
      <div className={getBaseTdClass(2)}>Status</div>
      <div className={getBaseTdClass(3)}>This Week’s Availability</div>
      <div className={getBaseTdClass(4)}>Daily Limit</div>
      <div className={getBaseTdClass(5)}></div>
    </div>);
}
export default function Dishes({ dishes, dishCount }) {
    const [page, setPage] = useState(0);
    const MAX_PAGE = Math.floor((dishes.length - 1) / MAX_ITEMS_PER_PAGE);
    const decrement = () => setPage((p) => (p <= 0 ? 0 : p - 1));
    const increment = () => setPage((p) => (p >= MAX_PAGE ? MAX_PAGE : p + 1));
    useEffect(() => setPage(0), [dishes]);
    const widths = ["w-16", "", "w-56", "", "w-20", "w-20"];
    return (<ContentContainer>
      <DesktopTitle label="Your Menu" newDishButton={dishes.length !== 0}/>
      <DishesNav dishCount={dishCount} dishes={dishes}/>
      <div className="px-8 md:pt-8  md:px-0 py-[80px] md:py-0 hide-scroll overflow-y-auto">
        <div className="flex flex-col md:table table-fixed w-full border-collapse">
          <TableRow widths={widths}/>

          {dishes
            .slice(page * MAX_ITEMS_PER_PAGE, page * MAX_ITEMS_PER_PAGE + MAX_ITEMS_PER_PAGE)
            .map((d) => (<Fragment key={d.id}>
                <SingleDishRowMobile dish={d}/>
                <SingleDishRowDesktop widths={widths} dish={d}/>
              </Fragment>))}
        </div>
      </div>
      <DishesPagination canIncrement={page !== MAX_PAGE} canDecrement={page !== 0} page={page} onDecrement={decrement} onIncrement={increment}/>
      <Nav label="Your Menu" newDishButton={dishes.length !== 0}/>
    </ContentContainer>);
}
