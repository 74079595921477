import { useState } from "react";
import ProgressBar from "../components/AddDishView/ProgressBar";
import CalendarEmbed from "../components/OnboardingView/CalendarEmbed";
import SurveyEmbed from "../components/OnboardingView/SurveyEmbed";
import TopSideBar from "../components/OnboardingView/TopSideBar";
export default function OnboardingView() {
    const [step, setStep] = useState(0);
    return (<form onSubmit={(e) => {
            e.preventDefault();
            setStep((s) => s + 1);
        }} className="md:min-h-screen flex flex-col md:flex-row">
      <TopSideBar onClose={() => { }} step={step}/>
      {step === 0 ? <SurveyEmbed /> : <CalendarEmbed />}
      <ProgressBar onBack={() => { }} percentage={20}/>
    </form>);
}
