import classNames from "classnames";
import { DishState } from "../../types/Dish";
import CommentCount from "../Global/CommentCount";
export default function DishStatusText({ dish }) {
    const s = dish.state;
    const attention = s === DishState.NEEDS_ATTENTION;
    return (<div className={classNames("flex whitespace-nowrap gap-2 font-medium text-sm items-center", {
            "text-red-500": attention,
        })}>
      {attention ? (<CommentCount amount={dish.comments.length}/>) : (<div className={classNames("mt-0.5 rounded-full h-3 w-3", {
                "bg-gray-400": s === DishState.INACTIVE,
                "bg-green-400": s === DishState.LIVE,
                "bg-yellow-400": s === DishState.DRAFT,
                "border-2 border-green-400": s === DishState.IN_REVIEW,
            })}></div>)}
      <span>{dish.state.replaceAll("_", " ").titleCase()}</span>
    </div>);
}
