import ElementSize from "../../types/ElementSize";
import ButtonStyle from "../../types/ButtonStyle";
import Button from "../Global/Button";
export default function ProgressBar({ onBack, percentage, }) {
    return (<div className="fixed md:w-1/2 bottom-0 bg-white w-full right-0">
      <div className="bg-white py-3 px-8 border-b-[2px] border-[#F5F5F5]">
        <div className="relative h-2 rounded bg-[#f5f5f5]">
          <div style={{ width: `${percentage}%` }} className="transition-all duration-300 absolute h-2 rounded bg-black"></div>
        </div>
      </div>
      <div className="px-8 flex py-8 justify-between">
        <div className="w-20">
          <Button onClick={onBack} style={ButtonStyle.GREY} size={ElementSize.S}>
            Back
          </Button>
        </div>
        <div className="w-20">
          <Button style={ButtonStyle.BLACK} size={ElementSize.S}>
            Next
          </Button>
        </div>
      </div>
    </div>);
}
