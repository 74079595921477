import ElementSize from "../../../types/ElementSize";
import ButtonStyle from "../../../types/ButtonStyle";
import Button from "../Button";
export default function DesktopTitle({ label, newDishButton, }) {
    return (<div className="md:pt-10 md:pb-12 hidden top-6 z-10 left-8 md:static md:flex items-center gap-3 font-bold text-xl md:text-3xl leading-7">
      <span className="whitespace-nowrap">{label}</span>
      {newDishButton ? (<div>
          <Button size={ElementSize.S} to="/dashboard/menu/add" style={ButtonStyle.BLACK}>
            + New dish
          </Button>
        </div>) : null}
    </div>);
}
