import classNames from "classnames";
export default function Dropdown({ open, options, onClick, values, }) {
    return (<div className={classNames("border absolute z-10 top-full bg-white overflow-x-auto transition-all duration-200 w-full rounded-xl overflow-y-auto bg-white", {
            "max-h-0 opacity-0 shadow-none border-0": !open,
            "max-h-[240px] opacity-1 mt-2 figma-shadow border-[#D1D5DB] ": open,
        })}>
      {options.map((o, index) => (<button tabIndex={-1} type="button" onClick={() => {
                onClick(o);
            }} className="text-left whitespace-nowrap w-full font-medium flex items-center px-6 gap-3 py-5 border-b-2 border-[#F5F5F5] last:border-b-0" key={`${o}${index}`}>
          <div className={classNames("min-w-[1.25rem] border-2 rounded-full h-5 w-5 border-[#f5f5f5]", {
                "bg-black border-black": values.includes(o),
            })}></div>
          <span className="truncate">{o.titleCase()}</span>
        </button>))}
    </div>);
}
