import PlayButtonIcon from "../../assets/icons/play-button.svg";
import MakePhotoImage from "../../assets/images/make-photo.png";
import ForChefsLogo from "../Global/ForChefsLogo";
import NavMenuCloseButton from "../Nav/NavMenuCloseButton";
const titles = [
    "Let’s give your dish a name",
    "Next, let’s add a photo of your dish",
    "Let’s describe your dish",
    "Now for the fun part - let’s set your price",
    "List all ingredients used in this dish",
    "Select allergens and dietary types if applicable",
    "Just a few last questions...",
];
export default function TopSideBar({ step, onClose, }) {
    return (<div className="z-10 md:justify-center md:items-center bg-[#F8FAFA] flex flex-col top-0 left-0 bg-white w-full">
      <div className="px-8 md:hidden">
        <NavMenuCloseButton onClick={onClose}/>
      </div>
      <div style={{ backgroundImage: step === 1 ? `url('${MakePhotoImage}')` : "none" }} className="pb-6 md:w-full md:px-20 md:h-full md:justify-center items-start md:py-0 px-8 pt-4 flex flex-col justify-between bg-cover bg-norepeat bg-center">
        <div className="absolute hidden md:block top-12">
          <ForChefsLogo white={step === 1}/>
        </div>
        {step === 1 ? (<a target="_blank" href="https://example.com" className="mt-20 px-2 mb-3 flex items-center gap-1 p-1 bg-[rgba(0,0,0,0.2)] rounded-3xl">
            <PlayButtonIcon className="w-4 h-4 md:w-5 md:h-5 mr-0.5"/>
            <span className="text-white text-xs md:text-lg font-medium">Watch 2 Min Video</span>
          </a>) : null}
        <span className={`font-bold pr-4 text-2xl md:text-[42px] md:leading-[58px] ${step === 1 ? "text-white" : "text-black"}`}>
          {titles[step]}
        </span>
        {step === 3 ? (<span className="md:text-lg md:mt-4 mt-1 block text-xs">
            We suggest ending each dish price with either .49 or .99.
          </span>) : null}
      </div>
    </div>);
}
