import { Link } from "react-router-dom";
import ElementSize from "../../types/ElementSize";
import ButtonStyle from "../../types/ButtonStyle";
import Button from "../Global/Button";
import DishStatusText from "./DishStatusText";
export default function SingleDishRowDesktop({ dish, widths }) {
    return (<Link className="hidden w-full md:table-row border-b-2 last:border-b-0 border-[#f5f5f5]" to={`/dashboard/menu/${dish.id}`}>
      <div className={`${widths[0]} p-4 pl-1 align-middle table-cell`}>
        <img src={dish.imageUrl} className="w-8 rounded-full h-8 min-w-[2rem]"/>
      </div>
      <span className={`${widths[1]} table-cell align-middle p-4 truncate font-medium`}>
        {dish.name.titleCase()}
      </span>
      <div className={`${widths[2]} table-cell p-4 align-middle`}>
        <DishStatusText dish={dish}/>
      </div>
      <span className={`${widths[3]} table-cell truncate whitespace-nowrap align-middle p-4 font-medium`}>
        Mon, Tue, Wed, Thur, Fri, Sat, Sun
      </span>
      <span className={`${widths[4]} table-cell truncate whitespace-nowrap align-middle p-4 font-medium`}>
        {dish.dailyLimit}
      </span>
      <div className={`${widths[5]} table-cell align-middle p-4 pr-0 text-right font-medium`}>
        <Button style={ButtonStyle.GREY} size={ElementSize.S}>
          Edit
        </Button>
      </div>
    </Link>);
}
