import { useEffect } from "react";
import Button from "../components/Global/Button";
import ForChefsLogo from "../components/Global/ForChefsLogo";
import { startConfetti } from "../confetti";
export default function DishAddedView() {
    useEffect(() => {
        setTimeout(() => {
            startConfetti();
        }, 1000);
    }, []);
    return (<div className="bg-black relative items-center py-16 text-white flex flex-col justify-center text-center px-8 min-h-screen min-w-screen">
      <div className="absolute top-12">
        <ForChefsLogo white/>
      </div>
      <div className="flex items-center flex-col">
        <h1 className="text-2xl mb-3 md:mb-8 md:text-6xl font-bold">Congratulations, Emilye!</h1>
        <span className="text-sm md:text-3xl md:leading-10">
          You’ve listed your first dish! Now let’s list the rest of your menu.
          <br className="hidden md:block"/>
          We’re excited for you to experience the magic of cooking on Neighbors.
        </span>

        <div className="w-44 hidden md:block mt-12">
          <Button>Continue</Button>
        </div>
      </div>
      <div className="w-44 absolute md:hidden bottom-24">
        <Button>Continue</Button>
      </div>
    </div>);
}
