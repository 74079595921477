import ButtonStyle from "../../types/ButtonStyle";
import ElementSize from "../../types/ElementSize";
import Button from "../Global/Button";
import NavMenuCloseButton from "../Nav/NavMenuCloseButton";
const details = {
    items: {
        "Italian Pasta": 2,
        "Garlic Pretzel": 1,
        "Matcha Cheesecake": 1,
    },
    tip: 499,
    total: 6466,
};
export default function OrderDetails({ onClose, }) {
    return (<div onClick={onClose} className="fixed md:flex md:justify-center md:items-center top-0 z-10 left-0 w-full h-full bg-[rgba(0,0,0,0.9)]">
      <div onClick={(e) => e.stopPropagation()} className="w-full md:h-auto md:rounded-3xl h-full md:max-w-sm bg-white px-8">
        <NavMenuCloseButton onClick={onClose}/>

        <div className="flex flex-col gap-3 md:gap-1 mb-7">
          <span className="font-bold text-xl">Order #123457</span>
          <span className="font-medium text-[#999999]">Samantha</span>
        </div>
        <div className="overflow-y-auto md:pb-0 pb-[112px] max-h-[75vh]">
          {Object.entries(details.items).map(([name, amount]) => (<div className="border-t-2 flex py-4 justify-between border-[#f5f5f5]">
              <span className="font-medium text-[#999999]">{amount} x</span>
              <span className="font-medium">{name}</span>
            </div>))}
          <div className="border-t-2 flex py-4 justify-between border-[#f5f5f5]">
            <span className="font-medium text-[#999999]">Tip</span>
            <span className="font-medium">${(details.tip / 100).toFixed(2)}</span>
          </div>
          <div className="border-t-2 flex py-4 justify-between border-[#f5f5f5]">
            <span className="font-medium text-[#999999]">Total</span>
            <span className="font-medium">${(details.total / 100).toFixed(2)}</span>
          </div>
        </div>
        <div className="fixed md:relative bottom-0 md:bottom-auto bg-white w-full right-0">
          <div className="px-8 md:px-0 flex py-8 justify-between">
            <Button onClick={onClose} style={ButtonStyle.BLACK} size={ElementSize.M}>
              Done
            </Button>
          </div>
        </div>
      </div>
    </div>);
}
