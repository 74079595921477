import ElementSize from "../../types/ElementSize";
import ButtonStyle from "../../types/ButtonStyle";
import Button from "../Global/Button";
export default function PhotoInstructions() {
    return (<div className="flex flex-col mt-5">
      <div className="font-bold mb-1">Instructions</div>
      <span className="font-medium text-[#909192]">
        Take a photo from directly above of your dish on a circular all-white bowl or plate,
        <br className="hidden md:block"/>
        ensuring no edges of the dish are cut off. Use natural sunlight to make your dish
        <br className="hidden md:block"/>
        colors pop!
      </span>
      <div className="self-start mt-4 md:mt-10">
        <Button size={ElementSize.S} style={ButtonStyle.GREY} href="https://example.com">
          Watch 2 Min Video
        </Button>
      </div>
    </div>);
}
