import { useState } from "react";
import PhotoInstructions from "./PhotoInstructions";
import PhotoExamples from "./PhotoExamples";
import PhotoUpload from "./PhotoUpload";
import FormWrapper from "./FormWrapper";
export default function DishPhotoForm() {
    const [file, setFile] = useState(null);
    return (<FormWrapper label="Dish photo">
      <div className="md:py-8 md:rounded-3xl md:px-12 md:shadow-[0px_4px_4px_rgba(0,0,0,0.03)] md:border border-[#F5F5F5]">
        <PhotoUpload file={file} setFile={setFile} acceptedFiles={[".jpeg", ".jpg", ".png"]} maxFileSize={1000000}/>
        <PhotoInstructions />
        <div className="hidden md:block my-8 h-0.5 bg-[#F5F5F5] w-full"></div>
        <PhotoExamples />
      </div>
    </FormWrapper>);
}
