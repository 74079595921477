import DishesImage from "../../assets/images/dishes.png";
import Button from "../Global/Button";
import ButtonStyle from "../../types/ButtonStyle";
import Nav from "../Nav";
function NoDishesText() {
    return (<>
      <div className="font-bold text-2xl md:text-[32px] md:leading-10 leading-8">
        <span>What dishes would you </span>
        <br className="md:hidden"/>
        <span>like to sell?</span>
      </div>
      <div className="text-[#999999] font-medium">
        <span>Add your first menu item. </span>
        <br className="md:hidden"/>
        <span>We’ll help you every step of the way.</span>
      </div>
    </>);
}
export default function NoDishes({ onClickTEST, }) {
    return (<>
      <div className="flex flex-col mt-24 md:mt-44 justify-center w-full items-center">
        <img src={DishesImage} className="w-36 md:w-48"/>
        <div className="flex my-8 gap-2 flex-col text-center">
          <NoDishesText />
        </div>
        <div>
          

          <Button style={ButtonStyle.BLACK} onClick={onClickTEST}>
            Add your dishes
          </Button>
        </div>
      </div>
      <Nav label="Your Menu"/>
    </>);
}
