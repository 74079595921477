import classNames from "classnames";
import { Link } from "react-router-dom";
import ElementSize from "../../../types/ElementSize";
import ButtonStyle from "../../../types/ButtonStyle";
export default function Button({ children, onClick, style, size, href, to, }) {
    if (!style)
        style = ButtonStyle.STANDARD;
    if (!size)
        size = ElementSize.M;
    const classes = classNames("flex justify-center items-center w-full rounded-100", {
        "font-bold text-lg leading-6 py-3 px-5": size === ElementSize.M,
        "font-medium text-base leading-6 py-2 px-3": size === ElementSize.S,
        "bg-[#F5F5F5]": style === ButtonStyle.GREY,
        "border border-black text-black bg-white": style === ButtonStyle.STANDARD,
        "bg-black text-white": style === ButtonStyle.BLACK,
    });
    if (href) {
        return (<a target="_blank" href={href} className={classes}>
        {children}
      </a>);
    }
    else if (to) {
        return (<Link to={to} className={classes}>
        {children}
      </Link>);
    }
    else {
        return (<button type={onClick ? "button" : "submit"} onClick={onClick} className={classes}>
        {children}
      </button>);
    }
}
