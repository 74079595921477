export var DishState;
(function (DishState) {
    DishState["LIVE"] = "LIVE";
    DishState["DRAFT"] = "DRAFT";
    DishState["IN_REVIEW"] = "IN_REVIEW";
    DishState["NEEDS_ATTENTION"] = "NEEDS_ATTENTION";
    DishState["INACTIVE"] = "INACTIVE";
})(DishState || (DishState = {}));
export var DishType;
(function (DishType) {
    DishType["ENTREE"] = "ENTREE";
    DishType["MAIN_COURSE"] = "MAIN_COURSE";
    DishType["DESERT"] = "DESERT";
    DishType["LUNCH"] = "LUNCH";
    DishType["DINNER"] = "DINNER";
})(DishType || (DishType = {}));
export var DishAllergens;
(function (DishAllergens) {
    DishAllergens["MILK"] = "MILK";
    DishAllergens["PEANUTS"] = "PEANUTS";
    DishAllergens["DAIRY"] = "DAIRY";
})(DishAllergens || (DishAllergens = {}));
export var DishDietaryTypes;
(function (DishDietaryTypes) {
    DishDietaryTypes["VEGAN"] = "VEGAN";
    DishDietaryTypes["LACTOSE_FREE"] = "LACTOSE_FREE";
    DishDietaryTypes["VEGETARIAN"] = "VEGETARIAN";
    DishDietaryTypes["GLUTEN_FREE"] = "GLUTEN_FREE";
    DishDietaryTypes["PALEO"] = "PALEO";
    DishDietaryTypes["KETOGENIC"] = "KETOGENIC";
    DishDietaryTypes["ORGANIC"] = "ORGANIC";
})(DishDietaryTypes || (DishDietaryTypes = {}));
export var DishServing;
(function (DishServing) {
    DishServing["OZ_CONTAINER"] = "OZ_CONTAINER";
    DishServing["LB_CONTAINER"] = "LB_CONTAINER";
})(DishServing || (DishServing = {}));
