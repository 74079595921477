import { Link } from "react-router-dom";
import ButtonStyle from "../../types/ButtonStyle";
import Button from "../Global/Button";
import NavMenuCloseButton from "./NavMenuCloseButton";
export default function NavMenu({ onClose, items, }) {
    return (<>
      <div className="border-b-2 border-[#F5F5F5]">
        <NavMenuCloseButton onClick={onClose}/>
      </div>
      {items.map((name) => (<Link key={name} onClick={onClose} className="font-medium leading-6 text-lg py-4 border-b-2 border-[#F5F5F5]" to={`/dashboard/${name}`}>
          {name.capitalize()}
        </Link>))}
      <div className="flex flex-col mt-5 gap-5">
        <Button onClick={() => { }}>Switch to ordering</Button>
        <Button onClick={() => { }} style={ButtonStyle.GREY}>
          Sign out
        </Button>
      </div>
    </>);
}
