import classNames from "classnames";
import ExclamationIcon from "../../../assets/icons/exclamation.svg";
import ElementSize from "../../../types/ElementSize";
export default function InputLabel({ htmlFor, text, showRequiredError, labelSize, }) {
    if (!labelSize)
        labelSize = ElementSize.M;
    return (<div className="flex gap-3 whitespace-nowrap items-center">
      <label htmlFor={htmlFor} className={classNames(`text-sm font-medium`, { "md:text-lg": labelSize === ElementSize.L })}>
        {text}
      </label>

      {showRequiredError ? (<div className={classNames("flex gap-1 text-red-500 text-sm font-medium items-center transition-all duration-200", {
                "opacity-1": showRequiredError,
                "opacity-0 pointer-events-none": !showRequiredError,
            })}>
          <ExclamationIcon width="14" height="14" className="text-red-500"/>
          <span>{text} is required</span>
        </div>) : null}
    </div>);
}
