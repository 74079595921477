import { Link } from "react-router-dom";
import DishStatusText from "./DishStatusText";
export default function SingleDishRow({ dish }) {
    return (<Link className="md:hidden w-full justify-between flex py-4 items-center border-b-2 last:border-b-0 border-[#f5f5f5]" to={`/dashboard/menu/${dish.id}`}>
      <div className="flex truncate gap-2 pr-4 items-center">
        <img src={dish.imageUrl} className="h-8 min-w-[2rem] w-8 rounded-full"/>
        <span className="whitespace-nowrap truncate font-medium">{dish.name.titleCase()}</span>
      </div>
      <DishStatusText dish={dish}/>
    </Link>);
}
