import classNames from "classnames";
import { useRef, useState } from "react";
import ElementSize from "../../../types/ElementSize";
import { randomString } from "../../../utils";
import InputLabel from "../InputLabel";
export default function Input({ label, onInput, required, placeholder, value, suffix, size, labelSize, }) {
    if (!size)
        size = ElementSize.M;
    const [dirty, setDirty] = useState(false);
    const id = useRef(label ? label.toLowerCase().replace(/[^a-z]/g, "_") + "_" + randomString() : randomString());
    const requiredError = dirty && required && !value;
    return (<div className="w-full relative flex gap-2 flex-col">
      {label ? (<InputLabel labelSize={labelSize} showRequiredError={requiredError} htmlFor={id.current} text={label}/>) : null}
      <input id={id.current} required={required} onInput={(e) => {
            setDirty(true);
            onInput(e);
        }} placeholder={requiredError ? "" : placeholder} value={value} className={classNames("truncate w-full figma-shadow rounded-xl resize-none min-w-0 border transition-all duration-200 border-[#D1D5DB] font-medium", {
            "bg-[#f1e1e1] border-[#E94848]": requiredError,
            "pr-16": suffix,
            "text-2xl md:text-4xl font-bold py-3 px-4": size === ElementSize.L,
            "py-4 px-6 text-base md:text-lg": size === ElementSize.M,
        })}/>
      {suffix ? (<span className="font-medium text-base md:text-lg absolute right-5 bottom-4.5">
          {suffix}
        </span>) : null}
    </div>);
}
