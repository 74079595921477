const INGREDIENTS = [
    "fine oats",
    "low-fat ricotta",
    "strawberries (fresh or frozen)",
    "almond milk (unsweetened)",
    "de-oiled cocoa powder",
    "honey",
    "cacao nibs",
    "eggs",
    "low-fat feta cheese",
    "diced tomato",
    "fresh spinach",
    "coconut oil",
    "quinoa",
    "low-fat quark",
    "coconut flakes (unsweetened)",
    "red currants",
    "water",
    "sweet potato",
    "halved cherry tomatoes",
    "diced onions",
    "minced ginger",
    "lemon grass",
    "red curry paste",
    "curry powder",
    "low-fat coconut milk",
    "mung bean sprouts",
    "firm tofu",
    "garlic",
    "limes",
    "halved bok choy",
    "fresh parsley",
    "chopped cilantro (coriander)",
    "virgin olive oil",
    "low-sodium soy sauce",
    "halved jalapeño",
    "whole-grain pasta (e.g. penne, fusilli)",
    "low-fat mozzarella",
    "eggplants",
    "sun-dried tomatoes (in olive oil)",
    "arugula",
    "dried oregano",
    "balsamic vinegar",
    "white beans (canned)",
    "cooked chickpeas",
    "cottage cheese",
    "diced cherry tomatoes",
    "lemons (zest and juice)",
    "chopped dill",
    "hard-boiled eggs",
    "Dijon mustard",
    "eggplant",
    "fresh thyme",
    "chopped garlic",
    "buttermilk",
    "full-fat Greek yogurt",
    "pomegranates",
    "winter squash or pumpkin (Hokkaido, butternut, muscat)",
    "ground flaxseeds",
    "roasted soybeans",
    "chopped hazelnuts",
    "grated nutmeg",
    "cinnamon powder",
    "ginger powder",
    "grated zucchini",
    "onions",
    "orange",
    "apple",
    "raisins",
    "grated ginger",
    "natural cashew butter",
    "chili flakes",
    "grilled chicken breast",
    "diced cucumbers",
    "sliced spring onions",
    "fresh mint",
    "ground lean beef",
    "chopped broccoli",
    "chopped almonds",
    "cilantro (coriander)",
    "sliced bananas",
    "apples",
    "natural peanut butter",
    "de-oiled coconut flour",
    "low-fat milk",
    "low-fat cream cheese",
    "soy yogurt (unsweetened)",
    "ripe banana",
    "unrefined salt",
    "ripe bananas",
    "egg",
    "fat-free yogurt",
    "oranges",
    "cooked beetroot",
    "kohlrabi",
    "diced apple",
    "natural almond butter",
    "whole-grain bread",
    "harzer cheese",
    "spring onion",
    "boneless chicken breast",
    "tomatoes",
    "fresh tuna (sashimi grade)",
    "avocados",
    "spring onions",
    "sesame seeds",
    "roasted sesame oil",
    "fresh cilantro (coriander)",
    "zucchini",
    "chopped onion",
    "peas (fresh or frozen)",
    "rosemary",
    "crayfish (cooked and peeled)",
    "sliced bell pepper",
    "mangoes",
    "celery",
    "cashews (unsalted)",
    "sesame oil",
    "lemons (juice)",
    "carrots",
    "tahina",
    "ground cumin",
    "paprika powder",
    "ripe kiwis",
    "chia seeds",
    "fresh vanilla (from a vanilla bean or vanilla mill)",
    "white fish fillet (cod, plaice, sole; fresh or frozen)",
    "grapefruits",
    "chopped jalapeño",
    "limes (juice)",
    "chopped cabbage",
    "dried dates",
    "rice milk",
    "blackberries",
    "red lentils",
    "red onions",
    "ginger",
    "turmeric powder",
    "ground coriander",
    "cayenne pepper",
    "bay leaves",
    "carrot juice (unsweetened)",
    "cucumbers",
    "shrimp or prawns (cooked and peeled)",
    "carrot",
    "sliced bell peppers",
    "red onion",
    "red bell pepper",
    "cherry tomatoes",
    "fresh basil",
    "dried tarragon",
    "lean beef (steak or fillet)",
    "jalapeño",
    "shirataki noodles",
    "mixed salad leaves",
    "sliced cucumbers",
    "grated carrot",
    "fried onions",
    "lime (juice)",
    "bananas",
    "egg whites",
    "blueberries",
    "dried apricots",
    "pears",
    "maple syrup",
    "Belgian endive (chicory)",
    "grated Parmesan cheese",
    "green beans (frozen)",
    "orange (zest and juice)",
    "waterpacked artichoke hearts (canned)",
    "broccoli",
    "brown mushrooms",
    "roasted pine nuts",
    "banana",
    "popped amaranth",
    "raspberries (fresh or frozen)",
    "spinach (frozen)",
    "grated carrots",
    "halloumi",
    "pine nuts",
    "orange (juice)",
    "smoked tofu",
    "capers",
    "leek",
    "smoked trout",
    "low-fat yogurt",
    "cold cuts turkey breast",
    "sliced cucumber",
    "chives",
    "hummus",
    "avocado",
    "chopped onions",
    "chopped tomatoes",
    "water-packed tuna",
    "sliced onion",
    "pitted green olives",
    "small romaine lettuce",
    "mixed Italian herbs",
    "smoked salmon",
    "sliced zucchini",
    "diced tomatoes",
    "dill",
    "red bell peppers",
    "cucumber",
    "fresh cauliflower",
    "diced onion",
    "potatoes",
    "cornichons",
    "tomato",
    "green pesto",
    "sliced avocados",
    "lean ham (slices)",
    "sliced bulbs of fennel",
    "fresh basil leaves",
    "sliced onions",
    "tomato puree",
    "garam masala",
    "brown rice",
    "lemons (zest)",
    "oranges (zest and flesh)",
    "limes (zest and juice)",
    "dried figs (unsweetened)",
    "oats",
    "fresh rosemary",
    "cauliflower (fresh or frozen)",
    "broccoli (fresh or frozen)",
    "dried thyme",
    "plum",
    "North Sea shrimp (cooked and peeled)",
    "fresh chives",
    "fresh dill",
    "chili pepper",
    "diced avocados",
    "red cabbage",
    "chopped walnuts",
    "diced apples",
    "seedless watermelon",
    "canned yellow corn",
    "lime (zest and juice)",
    "red radish",
    "chopped chives",
    "mango",
    "cardamom pods",
    "hard-boiled egg",
    "chopped chinese cabbage",
    "chopped bok choy",
    "chopped tomato",
    "spinach",
    "Parmesan cheese",
    "bulbs of fennel",
    "nashi pear",
    "diced mangoes",
    "roasted peanuts (unsalted)",
    "canned kidney beans",
    "sliced carrots",
    "grated horseradish",
    "trimmed green asparagus",
    "sliced banana",
    "sliced eggplants",
    "bulb of fennel",
    "onion",
    "persimmons",
    "coconut water",
    "kiwis",
    "sliced apple",
    "mixed berries (fresh or frozen)",
    "pear",
    "diced mango",
    "persimmon",
    "kiwi",
    "lemon (zest and juice)",
    "lemon (juice)",
    "small romaine lettuces",
    "oranges (juice)",
    "pomegranate",
    "plums",
    "nashi pears",
    "sliced avocado",
    "sliced bulb of fennel",
    "lemon (zest)",
    "diced cucumber",
    "sliced apples",
    "diced avocado",
    "orange (zest and flesh)",
    "jalapeños",
    "eggplants cut lengthwise",
    "oranges (zest and juice)",
    "ground almonds",
    "bay leaf",
    "egg white",
    "parsley root",
    "ripe kiwi",
    "chili peppers",
    "melon (honey, galia or cantaloupe)",
    "strawberries",
    "bok choy",
    "sliced carrot",
    "red radishes",
    "broccoli (frozen)",
    "peas (frozen)",
    "snow peas",
    "walnuts",
    "Brazil nuts",
    "cooked salmon fillet",
    "dried dates (pitted)",
    "orange juice (unsweetened)",
    "frozen strawberries",
    "spinach (fresh or frozen)",
    "passion fruit",
    "bell pepper",
    "flaxseeds",
    "peeled pumpkin seeds",
    "bell peppers",
    "butter (best if grass-fed and unsalted)",
    "black sesame",
    "pickled onions",
    "walnut oil",
    "lemon balm",
    "ripe avocados",
    "green bell pepper",
    "radicchio",
    "sunflower seeds",
    "cauliflower",
    "green bell peppers",
    "fresh pineapple",
    "sliced chili peppers",
    "sambal oelek",
    "peanuts (unsalted)",
    "small potatoes",
    "roasted sesame seeds",
    "black beans (canned)",
    "diced red onions",
    "sliced chili pepper",
    "pecan nuts",
    "medjool dates (pitted)",
    "leeks (green parts)",
    "chopped tomatoes (canned)",
    "green lentils",
    "cooked potatoes",
    "white asparagus",
    "Belgian endives (chicories)",
    "apple juice (unsweetened)",
    "medjool date (pitted)",
    "medjool dates",
    "organic vegetable stock",
    "precooked chestnuts",
    "leek (green part)",
    "lemon",
    "diced red onion",
    "ripe avocado",
    "fresh salmon fillet",
    "salmon fillet (fresh or frozen)",
    "dried seaweed salad or wakame",
    "watercress",
    "wild rice",
    "butternut squash",
    "roasted pumpkin seeds",
    "sardines in oil (skinned and boned)",
    "baby bok choy",
    "sliced spring onion",
    "rice vinegar",
    "silken tofu",
    "linseed oil",
    "chopped parsley",
    "almonds",
    "coconut yogurt",
    "fat-free Greek yogurt",
    "grapes",
    "hazelnuts",
    "mixed berries",
    "pumpkin seeds",
    "raspberries",
    "watermelons",
    "whole yogurt",
    "fresh fig",
    "fresh figs",
    "green or red grapes",
    "grapefruit",
    "peach",
    "peaches",
    "alfalfa sprouts",
    "dark chocolate (sugar-free)",
    "sliced crisp bread (whole-grain)",
    "green grapes",
    "rice crackers",
    "sour cherries (frozen)",
    "natural applesauce (unsweetened)",
    "organic wild prawns",
    "hokkaido squash",
    "kale",
    "goat cream cheese",
    "hazelnut oil",
    "sage",
    "Parma ham",
    "fresh white fish fillet (sashimi grade)",
    "hot chili sauce",
    "whole-grain flour",
    "baking powder",
    "white cabbage",
    "white wine vinegar",
    "beluga lentils",
    "whole milk",
    "grapefruits (juice)",
    "sparkling water",
    "flavored oil (e.g., truffle, orange, herb, etc.)",
    "instant couscous",
    "big tomatoes",
    "big tomato",
    "roasted sunflower seeds",
    "diced red bell peppers",
    "ajvar",
    "diced red bell pepper",
    "lemons",
    "whole-grain wrap",
    "iceberg lettuce",
    "whole-grain wraps",
    "grated low-fat cheddar cheese",
    "Asian mushroom mix (e.g., shiitake, enoki, oyster, etc.)",
    "chopped green pointed peppers",
    "chopped green pointed pepper",
    "oil-packed tuna",
    "cooked quinoa",
    "diced bell peppers",
    "chopped red bell peppers",
    "chopped red bell pepper",
    "diced green bell peppers",
    "chopped fresh mint",
    "diced green bell pepper",
    "chopped jalapeños",
    "brown mushroom",
    "Acids",
    "Acidity regulators",
    "Anticaking agents",
    "Antifoaming agents",
    "Antioxidants",
    "Bulking agents",
    "Food coloring",
    "Color retention agents",
    "Emulsifiers",
    "Flavors",
    "Flavor enhancers",
    "Flour treatment agents",
    "Glazing agents",
    "Humectants",
    "Tracer gas",
    "Preservatives",
    "Stabilizers",
    "Sweeteners",
    "Thickeners",
];
export default INGREDIENTS;
