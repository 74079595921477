"use strict";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./extensions";
if (process.env.NODE_ENV === "development") {
}
if (process.env.NODE_ENV === "production") {
}
const render = (root) => {
    root.render(<StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>);
};
const container = document.getElementById("root");
const root = createRoot(container);
if (module.hot)
    module.hot.accept("./App", () => render(root));
render(root);
