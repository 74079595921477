import classNames from "classnames";
import NavMenu from "./NavMenu";
export default function NavDesktopMenu({ onClose, isOpen, items, }) {
    return (<>
      <div onClick={onClose} className={classNames("bg-[rgba(0,0,0,0.25)] hidden md:block fixed top-0 z-10 left-0 transition-all duration-200 w-full h-full", {
            "opacity-0 pointer-events-none": !isOpen,
        })}></div>
      <nav className={classNames("shadow-[0px_4px_4px_rgba(0,0,0,0.07)] hidden transition-all md:flex pb-8 md:px-6 w-full md:pb-6 rounded-3xl w-80 flex-col px-8 duration-200 absolute top-0 right-0 border border-[#ECECEC] bg-white z-20", { "opacity-0 pointer-events-none": !isOpen })}>
        <NavMenu onClose={onClose} items={items}/>
      </nav>
    </>);
}
