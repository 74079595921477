import { NavLink } from "react-router-dom";
import ButtonStyle from "../../types/ButtonStyle";
import ElementSize from "../../types/ElementSize";
import Button from "../Global/Button";
import Select from "../Global/Select";
export default function OrdersNav() {
    const activeClasses = "-mb-[2px] transition-all duration-200 font-medium border-b-2 border-black block pb-4 pt-2";
    const inactiveClasses = "-mb-[2px] transition-all duration-200 border-b-2 border-[#F5F5F5] font-medium block text-[#999999] pb-4 pt-2";
    return (<div className="bg-white md:justify-between w-full items-center md:pb-0 flex flex-col md:flex-row gap-5 w-full">
      <div className="w-full flex gap-8 border-b-2 border-[#F5F5F5] ">
        <NavLink end className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)} to="/orders/sold">
          Sold
        </NavLink>
        <NavLink end className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)} to="/orders/active">
          Orders (66)
        </NavLink>
        <NavLink end className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)} to="/orders/refunded">
          Refunded (7)
        </NavLink>
      </div>

      <div className="flex justify-end w-full gap-2 items-center">
        <div className="md:w-48 w-full">
          <Select options={["Dymo", "Inkjet", "Laser printer"]} setValues={() => { }} values={["Dymo"]} size={ElementSize.S} single/>
        </div>
        <div className="md:w-48 w-full">
          <Button size={ElementSize.M} style={ButtonStyle.BLACK}>
            Print labels
          </Button>
        </div>
      </div>
    </div>);
}
