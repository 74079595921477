import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dishes from "../components/MenuView/Dishes";
import NoDishes from "../components/MenuView/NoDishes";
import { GET_DISHES } from "../mocks/dishes";
import { DishState } from "../types/Dish";
import AddDishView from "./AddDishView";
import DishAddedView from "./DishAddedView";
import DishDetailView from "./DishDetailView";
export default function MenuView() {
    const [dishes, setDishes] = useState([]);
    useEffect(() => {
        GET_DISHES().then((r) => setDishes(r));
    }, []);
    return (<>
      
        {dishes.length === 0 ? (<NoDishes onClickTEST={() => GET_DISHES().then((r) => setDishes(r))}/>) : (<Routes>
            <Route path="/" element={<Dishes dishCount={dishes.length} dishes={dishes}/>}/>
            <Route path="/live" element={<Dishes dishCount={dishes.length} dishes={dishes.filter((d) => d.state === DishState.LIVE)}/>}/>
            <Route path="/add" element={<AddDishView />}/>
            <Route path="/added" element={<DishAddedView />}/>
            <Route path="/:id" element={<DishDetailView />}/>
          </Routes>)}
      
      
    </>);
}
